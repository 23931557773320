import React, { useState } from 'react'
import arrowDownSVG from '../assets/icons/chev-down.svg'

const RoomGuestDropDown = ({
  rooms,
  handleAddRoom,
  handleRemoveRoom,
  handleUpdateCount,
  options = {}
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { maxRoomGuests = 6, minRoomAdults = 1 } = options

  const numberOfRooms = Object.keys(rooms || {})?.length
  const totalGuests = Object.values(rooms || {}).reduce(
    (obj, room) => {
      return {
        adults: (obj.adults += room.adults || 0),
        children: (obj.children += room.children || 0)
      }
    },
    {
      adults: 0,
      children: 0
    }
  )
  const text = `${numberOfRooms} Room${numberOfRooms > 1 ? 's' : ''}, ${
    totalGuests.adults
  } Adult${totalGuests.children > 1 ? 's' : ''}, ${totalGuests.children} ${
    totalGuests.children === 0 || totalGuests.children > 1
      ? 'Children'
      : 'Child'
  }`

  const maximumRooms = options?.maximumRooms ?? 7
  const roomsCount = Object.keys(rooms).length

  return (
    <div className="guest-dropdown-container">
      <button
        className="btn u-text-left u-border u-full-width"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="u-remove-margin room-font">Rooms</p>
        <div className="u-flex u-flex-space-between">
          <p className="u-remove-margin member">{text}</p>
          <img src={arrowDownSVG} alt="Down Icon" />
        </div>
      </button>

      <div className={`guest-dropdown-room-container ${isOpen ? 'open' : ''}`}>
        <div className="header u-flex u-flex-space-between u-flex-align-center">
          <p className="title">Rooms & Guests</p>
          <button
            className="btn"
            type="button"
            onClick={() => setIsOpen(false)}
          >
            Close X
          </button>
        </div>
        <div className="rooms">
          {Object.entries(rooms || {}).map(([id, room], index) => {
            const totalRoomGuests = +room.adults + +room.children
            return (
              <div key={id} className="u-flex room">
                <div className="room-title">
                  <p className="room-number">ROOM {index + 1}</p>
                  {roomsCount > 1 && (
                    <button
                      className="btn remove-room"
                      onClick={() => handleRemoveRoom(id)}
                    >
                      Remove Room -
                    </button>
                  )}
                </div>
                <div className="room-counts">
                  <div className="adult-container">
                    <div className="labels">
                      <p>Adults</p>
                      <p className="age-font">16+ Years</p>
                    </div>
                    <div className="counters">
                      <button
                        disabled={room.adults <= minRoomAdults}
                        onClick={() => {
                          handleUpdateCount(id, 'adults', room.adults - 1)
                        }}
                      >
                        -
                      </button>
                      <p>{room.adults}</p>
                      <button
                        disabled={totalRoomGuests >= maxRoomGuests}
                        onClick={() => {
                          handleUpdateCount(id, 'adults', room.adults + 1)
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="child-container">
                    <div className="labels">
                      <p>Children</p>
                      <p className="age-font">0-15 Years</p>
                    </div>
                    <div className="counters">
                      <button
                        disabled={room.children === 0}
                        onClick={() => {
                          handleUpdateCount(id, 'children', room.children - 1)
                        }}
                      >
                        -
                      </button>
                      <p>{room.children}</p>
                      <button
                        disabled={totalRoomGuests >= maxRoomGuests}
                        onClick={() => {
                          handleUpdateCount(id, 'children', room.children + 1)
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <div className="footer">
          <button
            className="btn add-room"
            onClick={() => handleAddRoom()}
            disabled={roomsCount === maximumRooms}
          >
            Add Room +
          </button>
          <hr className="line"></hr>
          <div className="guest-dropdown-footer">
            <button
              className="btn btn-primary"
              onClick={e => {
                e.stopPropagation()
                setIsOpen(false)
              }}
            >
              Update Guests
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoomGuestDropDown
