import React from 'react'
import { useSelector } from 'react-redux'
import { getLabels } from '../redux/reducers/configuration'

const RoomGuestSelection = ({
  room,
  id,
  roomNumber,
  // handleRemoveRoom,
  handleUpdateCount
}) => {
  const labels = useSelector(getLabels)

  function renderOptions(type) {
    let max = 5

    if (type === 'children' && room.adults) {
      max = 5 - Number(room.adults)
    } else if (type === 'adults' && room.children) {
      max = 5 - Number(room.children)
    }

    let i = type === 'adults' ? 1 : 0
    let options = []

    while (i <= max) {
      options.push(
        <option key={`${type}-option-${i}`} value={i}>
          {i}
        </option>
      )
      i++
    }

    return options
  }

  return (
    <div key={id} className="room-container">
      <div className="u-flex guests-selectors">
        <div className="guests-selector">
          <label htmlFor={`${id}-adults`} className="u-remove-margin">
            {labels.adults}
          </label>
          <select
            id={`${id}-adults`}
            value={room.adults}
            data-testid={`adult-select-${roomNumber}`}
            onChange={e => handleUpdateCount(id, 'adults', e.target.value)}
          >
            {renderOptions('adults')}
          </select>
        </div>
        <div className="guests-selector">
          <label htmlFor={`${id}-children`} className="u-remove-margin">
            {labels.children}
          </label>
          <select
            id={`${id}-children`}
            value={room.children}
            data-testid={`child-select-${roomNumber}`}
            onChange={e => handleUpdateCount(id, 'children', e.target.value)}
          >
            {renderOptions('children')}
          </select>
        </div>
      </div>
    </div>
  )
}

export default RoomGuestSelection
