import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import SpecialCodes from '../special-codes/SpecialCodes'

import { getLabels } from '../../redux/reducers/configuration'
import { getPackages } from '../../redux/reducers/packages'
import generateId from '../../utilities/helpers/generateId'
import RoomGuestSelection from '../RoomGuestSelection'
import Properties from '../property/Properties'
import Dates from '../dates/Dates'
import RoomGuestDropDown from '../RoomGuestDropDown'

const NewReservationForm = ({
  initialOptions,
  values,
  onValuesChange,
  onSubmit,
  selectedTab,
  jumpEndBy
}) => {
  const labels = useSelector(getLabels)
  const packages = useSelector(getPackages)

  const [datePickerIsVisible, setDatepickerIsVisible] = useState(false)
  const [specialCodesIsVisible, setSpecialCodesIsVisible] = useState(false)
  const [focusedInput, setFocusedInput] = useState(null)
  const minNights = get(initialOptions, 'minNights', 1)
  const maxNights = get(initialOptions, 'maxNights', null)
  const { arrivalDate, departureDate, rooms, promoCodes, propertyCode } = values
  const properties = get(initialOptions, 'properties', [])

  const validCodes = packages.map(item => item.id)

  function handleOpenDatePicker(targetInput) {
    let initialFocus = 'startDate'

    if (targetInput === 'endDate' && arrivalDate) {
      initialFocus = 'endDate'
    }

    setFocusedInput(initialFocus)
    setDatepickerIsVisible(true)
  }

  function isFormValid() {
    if (!arrivalDate || !departureDate) {
      return false
    }

    if (!rooms || !Object.keys(rooms).length) {
      return false
    }

    if (Object.values(rooms).some(room => room.adults > 0)) {
      return true
    }
  }

  function handleChange(key, value) {
    onValuesChange({
      ...values,
      [key]: value
    })
  }

  function handleStartDateChange(startDate, endDate = null) {
    onValuesChange({
      ...values,
      arrivalDate: startDate,
      departureDate:
        endDate ||
        moment(startDate)
          .clone()
          .add(jumpEndBy, 'days')
    })
  }

  function handleAddRoom() {
    handleChange('rooms', {
      ...rooms,
      [generateId()]: { adults: 2, children: 0 }
    })
  }

  function handleRemoveRoom(id) {
    const currentRooms = { ...rooms }
    delete currentRooms[id]
    handleChange('rooms', currentRooms)
  }

  function handleUpdateCount(id, type, count) {
    handleChange('rooms', {
      ...rooms,
      [id]: {
        ...rooms[id],
        [type]: count
      }
    })
  }

  return (
    <div className="query-reservation-card-inputs">
      {initialOptions &&
        initialOptions.displayPropertyField &&
        !isEmpty(properties) && (
          <Properties
            handleUpdateProperty={propertyCode =>
              handleChange('propertyCode', propertyCode)
            }
            properties={properties}
            propertyValue={propertyCode}
          />
        )}

      <Dates
        selectedStartDate={arrivalDate}
        handleOpenDatePicker={handleOpenDatePicker}
        selectedEndDate={departureDate}
        setSelectedStartDate={handleStartDateChange}
        focusedInput={focusedInput}
        setSelectedEndDate={endDate => handleChange('departureDate', endDate)}
        setFocusedInput={setFocusedInput}
        isVisible={datePickerIsVisible}
        minNights={minNights}
        maxNights={maxNights}
        setDatepickerIsVisible={setDatepickerIsVisible}
        propertyCode={propertyCode}
        isSingleDate={selectedTab === 'tours'}
      />
      {/* ROOMS */}
      <div className="u-flex u-flex-space-between rooms-label">
        <p className="label u-remove-margin">
          {selectedTab === 'tours' ? labels.guests : 'Rooms & Guests'}
        </p>
      </div>
      {initialOptions?.useMultiRoomDropdown ? (
        <RoomGuestDropDown
          rooms={rooms}
          options={initialOptions || {}}
          handleAddRoom={handleAddRoom}
          handleRemoveRoom={handleRemoveRoom}
          handleUpdateCount={handleUpdateCount}
        />
      ) : (
        <>
          {Object.entries(rooms).map(([id, room], i) => {
            return (
              <RoomGuestSelection
                key={id}
                id={id}
                room={room}
                roomNumber={i + 1}
                handleAddRoom={handleAddRoom}
                handleRemoveRoom={handleRemoveRoom}
                handleUpdateCount={handleUpdateCount}
              />
            )
          })}
        </>
      )}

      {initialOptions &&
        (initialOptions.enablePromoCode ||
          initialOptions.enableRateGroupCode) && (
          <SpecialCodes
            displayPromoField={initialOptions.enablePromoCode}
            displayRateField={initialOptions.enableRateGroupCode}
            isExpanded={specialCodesIsVisible}
            handleExpandEvent={setSpecialCodesIsVisible}
            labels={labels}
            promoCodes={promoCodes}
            setPromoCodes={promoCodes => handleChange('promoCodes', promoCodes)}
            validCodes={validCodes}
          />
        )}

      <div className="submit-container">
        <button
          className="btn btn-primary submit-btn u-full-width"
          onClick={onSubmit}
          disabled={!isFormValid()}
        >
          {!isEmpty(initialOptions.submitButtonContent)
            ? initialOptions.submitButtonContent
            : labels.checkRates}
        </button>
      </div>
    </div>
  )
}

export default NewReservationForm
